<template>
    <Link :style="{ color: color, fontSize: size + 'px' }" :class="className">
        <SvgArrowLeft v-if="left" :class="[left ? 'mr-5' : '']" :color="color"/>
        {{ text }}
        <SvgArrowRight
            v-if="right"
            :class="[right ? 'ml-5' : '']"
            :color="color"
    /></Link>
</template>
<script>
import { Link } from './style';
import SvgArrowLeft from '../../shared/icons/SvgArrowLeft';
import SvgArrowRight from '../../shared/icons/SvgArrowRight';
export default {
    name: 'IconLink',
    components: {
        Link,
        SvgArrowLeft,
        SvgArrowRight,
    },
    props: {
        text: {
            type: String,
            required: true,
        },
        left: {
            type: Boolean,
        },
        right: {
            type: Boolean,
        },
        size: {
            type: String,
        },
        color: {
            type: String,
        },
        className: {
            type: String,
        },
    },
};
</script>
<style scoped></style>
