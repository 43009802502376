<template>
    <div>
        <div class="tw-flex tw-justify-between tw-py-5 tw-px-3">
            <div class="d-none d-sm-flex tw-items-center tw-py-5">
                <img :src="article.picture" :alt="'article'" width="210" />
            </div>
            <div class="tw-flex tw-flex-col tw-py-5 article">
                <div class="article-content">
                    <div class="tw-flex tw-justify-between">
                        <div class="font-weight-medium article-title tw-pb-5">
                            {{ article.title }}
                        </div>
                        <v-select
                            v-if="basket"
                            v-model="counter"
                            :items="numbers"
                            outlined
                        ></v-select>
                    </div>
                    <div class="tw-font-light article-informations">
                        Menge: {{ article.number }}
                    </div>
                    <div class="tw-font-light article-informations">
                        Marke: {{ article.brand }}
                    </div>
                    <div class="tw-font-light article-informations">
                        Größe: {{ article.size }}
                    </div>
                    <div class="tw-font-light article-informations">
                        Farbe: {{ article.color }}
                    </div>
                    <div class="tw-font-light article-informations">
                        Artikelnummer: {{ article.id }}
                    </div>
                </div>
                <div
                    :class="
                        `tw-flex article-actions tw-items-end tw-justify-${
                            basket ? 'between' : 'end'
                        }`
                    "
                >
                    <div v-if="basket">
                        <v-btn text tile>
                            <img
                                src="@/assets/images/Icons/icon-wishlist.svg"
                                class="tw-w-8 tw-mr-3"
                            />
                            <span class="tw-text-base"
                                >Auf die Wunschliste</span
                            >
                        </v-btn>
                        <v-btn text tile>
                            <img
                                src="@/assets/images/Icons/iIcon_artikel-entfernen.svg"
                                class="tw-w-6 tw-mr-3"
                            />
                            <span class="tw-text-base">Artikel entfernen</span>
                        </v-btn>
                    </div>
                    <div class="article-prices">
                        <div class="tw-font-light tw-line-through">
                            {{ article.currency }} {{ article.originalPrice }}
                        </div>
                        <div class="tw-font-normal">
                            {{ article.currency }}
                            {{ article.promotionalPrice }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <v-divider />
    </div>
</template>

<script>
export default {
    name: 'ArticleOverview',
    data() {
        return {
            themePrimary: this.$vuetify.theme.themes.light.primary.base,
            counter: '1',
            numbers: ['1', '2', '3', '4'],
        };
    },
    props: {
        article: Object,
        basket: Boolean,
    },
    updated: () => {},
    methods: {},
};
</script>
<style scoped>
.v-input >>> .v-input__slot {
    border-radius: 0;
    width: 65px;
}
.v-input >>> fieldset {
    border-color: #9e9e9e;
    color: #858585;
}
.article {
    width: 100%;
}

.article .article-title {
    font-size: 21px;
    line-height: 1.3;
}

@media (min-width: 960px) and (max-width: 1023px) {
    .article .article-title {
        font-size: 15px;
    }
    .article .article-informations {
        font-size: 12px !important;
    }
}

.article .article-informations {
    font-size: 16px;
    color: black;
}

.v-btn >>> .v-btn__content {
    color: #464646;
    text-transform: lowercase;
    font-weight: 200;
}

.v-input >>> .v-input__control {
    display: flex;
    flex-direction: none;
    height: auto;
    justify-content: flex-end;
    flex-grow: 0;
    flex-wrap: nowrap;
    min-width: 0;
    width: 100%;
}
.v-input >>> .v-input__control .v-text-field__details {
    display: none;
}
</style>
