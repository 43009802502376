<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        viewBox="0 0 40 40"
        :aria-labelledby="iconName"
        role="presentation"
        :style="{ fill: color }"
    >
        <title :id="iconName" lang="en">{{ iconName }} icon</title>
        <path
            id="Pfad_395"
            data-name="Pfad 395"
            d="M19.982,0A19.982,19.982,0,1,0,39.963,19.982,20.04,20.04,0,0,0,19.982,0Zm0,36.654A16.672,16.672,0,1,1,36.654,19.982,16.709,16.709,0,0,1,19.982,36.654Z"
            fill="#27348b"
        />
        <circle
            id="Ellipse_12"
            data-name="Ellipse 12"
            cx="2.498"
            cy="2.498"
            r="2.498"
            transform="translate(9.991 11.677)"
            fill="#27348b"
        />
        <circle
            id="Ellipse_13"
            data-name="Ellipse 13"
            cx="2.498"
            cy="2.498"
            r="2.498"
            transform="translate(24.977 11.677)"
            fill="#27348b"
        />
        <path
            id="Pfad_396"
            data-name="Pfad 396"
            d="M42.952,31.5a1.606,1.606,0,0,0-1.686,1.686,8.208,8.208,0,0,1-8.18,8.18,1.686,1.686,0,0,0,0,3.372A11.539,11.539,0,0,0,44.575,33.248,1.644,1.644,0,0,0,42.952,31.5Z"
            transform="translate(-11.793 -11.831)"
            fill="#27348b"
        />
    </svg>
</template>
<script>
export default {
    name: 'SvgSmileEmoji',
    props: {
        iconName: {
            type: String,
            default: 'purple',
        },
        width: {
            type: [Number, String],
            default: 20,
        },
        height: {
            type: [Number, String],
            default: 20,
        },
        color: {
            type: String,
            default: '#26348c',
        },
    },
};
</script>
