<template>
    <v-container>
        <v-row justify="space-between">
            <v-col cols="12" sm="12" class="tw-pt-0">
                <div
                    class="tw-flex tw-items-center lg:tw-flex-row tw-flex-col tw-justify-start tw-mx-auto tw-pb-10"
                >
                    <Title
                        class="tw-pr-2 tw-text-lg xl:tw-text-3xl"
                        :name="'Bestellübersicht'"
                        :size="'30'"
                    />
                    <div class="tw-font-light">(2 Artikel)</div>
                </div>
                <div class="tw-flex tw-justify-between tw-pb-5 tw-medium-text">
                    <div class="tw-font-light">Zwischensumme</div>
                    <div class="font-weight-medium rtl">EUR 190,00</div>
                </div>
                <div class="tw-flex tw-justify-between tw-pb-5 medium-text">
                    <div class="tw-font-light">Verpackung & Versand</div>
                    <div class="font-weight-medium rtl">Kostenlos</div>
                </div>
                <v-divider />
                <div class="tw-flex tw-justify-between tw-py-5 medium-text">
                    <div class="tw-flex">
                        <div class="font-weight-medium tw-pr-2">
                            Gesamtsumme
                        </div>
                        <div class="tw-font-light">inkl. MwSt.</div>
                    </div>
                    <div class="font-weight-medium rtl">EUR 190,00</div>
                </div>
                <v-divider />
                <v-row v-if="basket">
                    <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        class="tw-pt-10 tw-pb-5 tw-px-3"
                    >
                        <v-btn
                            depressed
                            block
                            color="primary"
                            class="tw-capitalize"
                            height="60"
                            @click="$router.push('checkout')"
                            tile
                        >
                            Jetzt sicher zur kasse
                        </v-btn>
                    </v-col>
                </v-row>
                <div class="tw-flex tw-justify-center tw-py-5 medium-text">
                    <SvgPriceTag
                        class="tw-mr-5"
                        :height="'50'"
                        :width="'50'"
                        :color="themePrimary"
                    />
                    <IconLink
                        class="tw-flex tw-justify-center tw-items-center"
                        :size="'18'"
                        right
                        :text="'Coupon-Code hinzufügen'"
                        :color="themePrimary"
                    />
                </div>
                <v-divider />
                <div class="tw-flex tw-items-start tw-flex-col medium-text">
                    <div
                        class="tw-flex tw-justify-center tw-items-center tw-pt-5 tw-pb-2"
                    >
                        <div class="svg-image">
                            <SvgReload
                                class="tw-mr-5"
                                :height="'35'"
                                :width="'35'"
                                :color="themePrimary"
                            />
                        </div>
                        <div>
                            <div class="font-weight-medium tw-pr-2">
                                30 Tage Rückgaberecht
                            </div>
                            <div class="tw-font-light">
                                Mit kostenlosem Rückversand
                            </div>
                        </div>
                    </div>
                    <div
                        class="tw-flex tw-justify-center tw-items-center tw-py-2"
                    >
                        <div class="svg-image">
                            <SvgPhone
                                class="tw-mr-5"
                                :height="'35'"
                                :width="'35'"
                                :color="themePrimary"
                            />
                        </div>
                        <div>
                            <div class="font-weight-medium tw-pr-2">
                                Telefonische Fachberatung
                            </div>
                            <div class="tw-font-light">
                                +49 6107 / 966 68 66. Mo. - Fr. 9 - 18 Uhr
                            </div>
                        </div>
                    </div>
                    <div
                        class="tw-flex tw-justify-center tw-items-center tw-pb-5 tw-pt-2"
                    >
                        <div class="svg-image">
                            <SvgSmileEmoji
                                class="tw-mr-5"
                                :height="'35'"
                                :width="'35'"
                                :color="themePrimary"
                            />
                        </div>
                        <div>
                            <div class="font-weight-medium tw-pr-2">
                                Lächel-Garantie
                            </div>
                            <div class="tw-font-light">
                                Wir sind für Sie da - garantiert persönlich
                            </div>
                        </div>
                    </div>
                </div>
                <v-divider />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Title from '@/components/shared/typography/Title';
import IconLink from '@/components/shared/buttons/IconLink';
import SvgPriceTag from '@/components/shared/icons/SvgPriceTag';
import SvgReload from '@/components/shared/icons/SvgReload';
import SvgPhone from '@/components/shared/icons/SvgPhone';
import SvgSmileEmoji from '@/components/shared/icons/SvgSmileEmoji';
export default {
    name: 'DeliveryOverview',
    components: {
        Title,
        SvgPriceTag,
        SvgReload,
        SvgPhone,
        SvgSmileEmoji,
        IconLink,
    },
    data() {
        return {
            themePrimary: this.$vuetify.theme.themes.light.primary.base,
        };
    },
    props: { basket: Boolean },
    updated: () => {},
    methods: {},
};
</script>
<style scoped>
.text-section {
    font-size: 18px;
    padding-bottom: 15px;
    font-weight: 300;
    line-height: 1.3;
    color: #858585;
}

@media (min-width: 640px) and (max-width: 1600px) {
    .medium-text {
        font-size: 13px;
    }
}
.rtl {
    direction: rtl;
}
.svg-image {
    width: 50px;
}
</style>
