<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        viewBox="0 0 43 51"
        :aria-labelledby="iconName"
        role="presentation"
        :style="{ fill: color }"
    >
        <title :id="iconName" lang="en">{{ iconName }} icon</title>
        <g
            id="Gruppe_1247"
            data-name="Gruppe 1247"
            transform="translate(830.273 -217.662)"
        >
            <path
                id="Pfad_507"
                data-name="Pfad 507"
                d="M-795.182,249.506l-6.772,13.667a2.48,2.48,0,0,0-.07,2.049l11.508,27.845a2.48,2.48,0,0,0,3.24,1.345l10.609-4.385,10.609-4.385a2.481,2.481,0,0,0,1.345-3.24l-11.508-27.845a2.48,2.48,0,0,0-1.5-1.4l-14.445-4.9A2.48,2.48,0,0,0-795.182,249.506Z"
                transform="translate(-24.311 -26.545)"
                fill="none"
                stroke="#27348b"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
            />
            <path
                id="Pfad_508"
                data-name="Pfad 508"
                d="M-688.635,321.322a2.908,2.908,0,0,0-3.8-1.577,2.908,2.908,0,0,0-1.577,3.8,2.908,2.908,0,0,0,3.8,1.577A2.908,2.908,0,0,0-688.635,321.322Z"
                transform="translate(-123.318 -92.01)"
                fill="none"
                stroke="#27348b"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
            />
            <line
                id="Linie_135"
                data-name="Linie 135"
                y1="16.949"
                x2="7.036"
                transform="translate(-810.894 239.521)"
                fill="none"
                stroke="#27348b"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
            />
            <circle
                id="Ellipse_25"
                data-name="Ellipse 25"
                cx="2.543"
                cy="2.543"
                r="2.543"
                transform="translate(-817.006 244.149) rotate(-24.617)"
                fill="none"
                stroke="#27348b"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
            />
            <path
                id="Pfad_509"
                data-name="Pfad 509"
                d="M-522.24,568.2a2.543,2.543,0,0,1-1.379,3.322,2.543,2.543,0,0,1-3.321-1.379,2.543,2.543,0,0,1,1.379-3.321A2.543,2.543,0,0,1-522.24,568.2Z"
                transform="translate(-276.531 -318.576)"
                fill="none"
                stroke="#27348b"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
            />
            <path
                id="Pfad_510"
                data-name="Pfad 510"
                d="M-815.084,229.019c-3.062-5.064-10.934-12.181-13.251-9.092-1.693,2.257,2.422,5.627,6.518,7.729"
                transform="translate(0)"
                fill="none"
                stroke="#27348b"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
            />
        </g>
    </svg>
</template>
<script>
export default {
    name: 'SvgPriceTag',
    props: {
        iconName: {
            type: String,
            default: 'purple',
        },
        width: {
            type: [Number, String],
            default: 20,
        },
        height: {
            type: [Number, String],
            default: 20,
        },
        color: {
            type: String,
            default: '#26348c',
        },
    },
};
</script>
